import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useRef, useState } from "react";
import BBSTextField from "../../component/inputs/BBSTextField";
import { IBBS, ILine } from "../../interfaces/bbs.interface";
import { IShapeCodes } from "../../interfaces/shapes.interface";
import bbsSvc from "../../services/bbs.service";
import { useAppSelector } from "../../store/hooks/hooks";
import { RootState } from "../../store/store/store";
import MembersListing from "./MembersListing";
import React from "react";
import { IStandard } from "../../interfaces/standard.interface";
import { setToast } from "../../store/features/toastSlice";

interface IProps {
  selectedLevelBBS: IBBS | null;
  shapeCodes: IShapeCodes[];
  standard: IStandard | null;
  refreshProjects: () => void;
}

function MemberDetails({
  selectedLevelBBS,
  shapeCodes,
  standard,
  refreshProjects,
}: IProps) {
  const prevBBS = useRef<string>("");
  const commonState = useAppSelector((state: RootState) => state.common);
  const [editID, setEditID] = useState<number | null>(null);
  const [errMsg, setErrMsg] = useState("");

  const [invalidFields, setInvalidFields] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectedBBS, setSelectedBBS] = useState<IBBS | null>(null);
  const [selectedShape, setSelectedShape] = useState<IShapeCodes | undefined>();

  const handleChange = (e: any) => {
    setSelectedBBS((prev) => {
      var updatedLines = prev?.lines;

      updatedLines?.forEach((item) => {
        item.noOfMem = e.target.value;
      });

      return {
        ...prev,
        lines: updatedLines,
      };
    });
  };

  const handleValidation = (name: string, isValid: boolean) => {
    setInvalidFields((prev) => ({
      ...prev,
      [name]: !isValid,
    }));
  };

  useEffect(() => {
    if (commonState?.selectedProject?.event?.item) {
      bbsSvc
        .getByCodeMemberName(
          commonState?.selectedProject?.event?.item.projectId,
          commonState?.selectedProject?.event?.item.levelCode,
          commonState?.selectedProject?.event?.item.subLevelCode,
          commonState?.selectedProject?.event?.item.text
        )
        .then((res) => {
          const bbs = findBBSWithMemberName(
            res.data as IBBS[],
            commonState?.selectedProject?.event?.item.text
          );
          if (bbs) {
            prevBBS.current = JSON.stringify(bbs);
            setSelectedBBS(bbs);
          }
        });
    }
  }, [commonState?.selectedProject?.event?.item]);

  function findBBSWithMemberName(allBbs: IBBS[], memberName: string) {
    const bbs = allBbs?.find((item) =>
      item.lines?.some((line) => line.name === memberName)
    );
    if (bbs) {
      if (bbs.lines) {
        const temp = bbs?.lines?.map((line: any, idx: number) => {
          return { ...line, idx };
        });

        return {
          ...bbs,
          lines: temp,
        };
      }
    }
  }

  const getPropertyFromLines = (key: string) => {
    if (!selectedBBS || selectedBBS?.lines?.length === 0) return "";
    const firstMem: ILine = selectedBBS.lines!.filter(
      (x) => x.name == commonState?.selectedProject?.event?.item.text
    )[0];
    if (firstMem?.hasOwnProperty(key)) {
      return firstMem[key as keyof ILine];
    }
    return "";
  };

  const getMembers = () => {
    if (commonState?.selectedProject?.event?.item) {
      const mems = selectedBBS?.lines?.filter((line) => {
        return line.name === commonState?.selectedProject?.event?.item?.text;
      });
      return mems;
    }
    return [];
  };

  const handleGridChange = (gridLines: ILine[]) => {
    if (gridLines.length === 0) return;
    const updatedLines = replaceItemsByIdx(gridLines, selectedBBS?.lines!);
    setSelectedBBS((prev) => {
      return {
        ...prev,
        lines: updatedLines,
      };
    });
  };

  function replaceItemsByIdx(updatedLines: ILine[], prevLines: ILine[]) {
    let replacementMap = new Map();

    updatedLines.forEach((item) => {
      replacementMap.set(item.idx, item);
    });

    return prevLines.map((item) => {
      return replacementMap.has(item.idx) ? replacementMap.get(item.idx) : item;
    });
  }

  const updateBBS = () => {
    setErrMsg("");
    bbsSvc.update(selectedBBS?.id!, selectedBBS).then((res) => {
      if (!res.error) {
        dispatch(
          setToast({
            toastType: "success",
            toastMsg: "Member updated successfully",
          })
        );
      }
      refreshProjects();
      setEditID(null);
    });
  };

  const cancelUpdate = () => {
    setErrMsg("");
    setEditID(null);
    setSelectedBBS(JSON.parse(prevBBS.current));
    setSelectedShape(undefined);
  };

  const handleBtnDisable = () => {
    if (prevBBS.current === JSON.stringify(selectedBBS)) {
      return true;
    }
    return false;
  };

  const getTotalWeights = () => {
    let totalWeight = 0;
    const members = getMembers();
    members?.forEach((line) => {
      totalWeight += line.weight!;
    });
    if (totalWeight) {
      return totalWeight.toString();
    }
    return "";
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-5 pb-4">
        <div className="card p-3">
          <h3 className="text-txtPrimary text-lg font-medium font-kanit tracking-[-0.04px] mb-4">
            Member Properties
          </h3>
          <div className="grid grid-cols-2 gap-3">
            <div>
              <BBSTextField
                name="mappingName"
                value={getPropertyFromLines("barRev")?.toString() || ""}
                onChange={handleChange}
                onValidate={handleValidation}
                label="Revision Number"
                required={false}
                placeholder=""
                disabled={true}
                regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                error="Invalid Name"
              />
            </div>
            <div>
              <BBSTextField
                name="templateHeaderMapping.sheetNumber"
                value={getPropertyFromLines("noOfMem")?.toString() || ""}
                onChange={handleChange}
                onValidate={handleValidation}
                label="No. of members"
                required={false}
                placeholder=""
                disabled={false}
                error="Invalid Sheet Number"
              />
            </div>
            <div>
              <BBSTextField
                name="templateHeaderMapping.sheetNumber"
                value={""}
                onChange={handleChange}
                onValidate={handleValidation}
                label="Engineered Members"
                required={false}
                placeholder=""
                disabled={true}
                error="Invalid Sheet Number"
              />
            </div>
            <div>
              <BBSTextField
                name="templateHeaderMapping.sheetNumber"
                value={""}
                onChange={handleChange}
                onValidate={handleValidation}
                label="Released Members"
                required={false}
                placeholder=""
                disabled={true}
                error="Invalid Sheet Number"
              />
            </div>
            <div>
              <BBSTextField
                name="templateHeaderMapping.sheetNumber"
                value={""}
                onChange={handleChange}
                onValidate={handleValidation}
                label="Fabricated Members"
                required={false}
                placeholder=""
                disabled={true}
                error="Invalid Sheet Number"
              />
            </div>
            <div>
              <BBSTextField
                name="templateHeaderMapping.sheetNumber"
                value={""}
                onChange={handleChange}
                onValidate={handleValidation}
                label="Erected Members"
                required={false}
                placeholder=""
                disabled={true}
                error="Invalid Sheet Number"
              />
            </div>
            <div>
              <BBSTextField
                name="templateHeaderMapping.sheetNumber"
                value={getTotalWeights()}
                onChange={handleChange}
                onValidate={handleValidation}
                label="Member Weight"
                required={false}
                placeholder=""
                disabled={true}
                error="Invalid Sheet Number"
              />
            </div>
          </div>
        </div>
        <div className="card p-3">
          <h3 className="text-txtPrimary text-lg font-medium font-kanit tracking-[-0.04px] mb-4">
            BBS Properties
          </h3>
          <div className="grid grid-cols-2 gap-3">
            <div>
              <BBSTextField
                name="mappingName"
                value={selectedBBS?.name}
                onChange={handleChange}
                onValidate={handleValidation}
                label="BBS Name"
                required={false}
                placeholder=""
                disabled={true}
                regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                error="Invalid Name"
              />
            </div>
            <div>
              <BBSTextField
                name="templateHeaderMapping.sheetNumber"
                value={selectedBBS?.shopDrawNo}
                onChange={handleChange}
                onValidate={handleValidation}
                label="Shop Drawing No."
                required={false}
                placeholder=""
                disabled={true}
                error="Invalid Sheet Number"
              />
            </div>
            <div>
              <BBSTextField
                name="templateHeaderMapping.sheetNumber"
                value={selectedBBS?.no}
                onChange={handleChange}
                onValidate={handleValidation}
                label="BBS No."
                required={false}
                placeholder=""
                disabled={true}
                error="Invalid BBS No."
              />
            </div>
            <div>
              <BBSTextField
                name="templateHeaderMapping.sheetNumber"
                value={selectedBBS?.shopDrawRevNo}
                onChange={handleChange}
                onValidate={handleValidation}
                label="Shop Drawing Revision No."
                required={false}
                placeholder=""
                disabled={true}
                error="Invalid Shop Drawing Revision No."
              />
            </div>
            <div>
              <BBSTextField
                name="templateHeaderMapping.sheetNumber"
                value={selectedBBS?.revNo}
                onChange={handleChange}
                onValidate={handleValidation}
                label="BBS Revision No."
                required={false}
                placeholder=""
                disabled={true}
                error="Invalid BBS Revision No."
              />
            </div>
            <div>
              <BBSTextField
                name="templateHeaderMapping.sheetNumber"
                value={selectedBBS?.detailingOff}
                onChange={handleChange}
                onValidate={handleValidation}
                label="Detailing Office"
                required={false}
                placeholder=""
                disabled={true}
                error="Invalid Detailing Office"
              />
            </div>
          </div>
        </div>
        <div className="card p-3 w-full max-w-[100vw]">
          <h3 className="text-txtPrimary text-lg font-medium font-kanit tracking-[-0.04px] mb-4">
            Unit Properties
          </h3>
          <div className="grid grid-cols-2 gap-3">
            <div>
              <BBSTextField
                name="dimensionsConstraint"
                value={selectedShape?.dimensionsConstraint}
                onChange={handleChange}
                onValidate={handleValidation}
                label="Shape Constraints"
                required={false}
                placeholder=""
                disabled={true}
                regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                error="Invalid Name"
              />
            </div>
            <div>
              <BBSTextField
                name="formula"
                value={selectedShape?.formula}
                onChange={handleChange}
                onValidate={handleValidation}
                label="Standard Constraints"
                required={false}
                placeholder=""
                disabled={true}
                regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                error="Invalid Name"
              />
            </div>
            <div>
              <BBSTextField
                name="revision"
                value=""
                onChange={handleChange}
                onValidate={handleValidation}
                label="Revision"
                required={false}
                placeholder=""
                disabled={true}
                regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                error="Invalid Name"
              />
            </div>
            <div>
              <BBSTextField
                name="code"
                value={selectedShape?.code}
                onChange={handleChange}
                onValidate={handleValidation}
                label="Obj Code"
                required={false}
                placeholder=""
                disabled={true}
                regex={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/}
                error="Invalid Name"
              />
            </div>
          </div>
        </div>
        {selectedShape && (
          <div className="card p-3 w-full">
            <h3 className="text-txtPrimary text-lg font-medium font-kanit tracking-[-0.04px] mb-4">
              Shape Preview
            </h3>
            <img src={selectedShape?.imagePath} width={250} />
          </div>
        )}
      </div>

      <div className="w-full max-w-[100vw]">
        <div className="grid grid-cols-2 gap-3">
          <MembersListing
            gridData={getMembers() || []}
            onChange={handleGridChange}
            shapeCodes={shapeCodes}
            selectedShape={selectedShape}
            setSelectedShape={setSelectedShape}
            standard={standard}
            editID={editID}
            setEditID={setEditID}
            handleBtnDisable={handleBtnDisable}
            updateBBS={updateBBS}
            cancelUpdate={cancelUpdate}
            errMsg={errMsg}
            setErrMsg={setErrMsg}
          />
        </div>
      </div>
    </>
  );
}

export default MemberDetails;
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
